

import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const HapinnessCardSkeletons: React.FC = () => {
    // Adjust the number of skeleton cards as needed
    const skeletonCards = Array.from({ length: 2 });

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {skeletonCards.map((_, index) => (
                <div
                    key={index}
                    className="flex border p-4 rounded-md shadow-sm bg-white gap-4"
                >
                    {/* Skeleton for Image */}
                    <Skeleton
                        className="w-28 h-24 rounded-md"
                        style={{ borderRadius: '8px' }}
                    />

                    {/* Skeleton for Text and Buttons */}
                    <div className="flex flex-col justify-between w-full">
                        {/* Title Skeleton */}
                        <Skeleton width={`80%`} height={20} className="mb-2" />
                        <Skeleton width={`60%`} height={20} className="mb-2" />

                        {/* Price and Button Skeleton */}
                        <div className="flex items-center justify-between mt-2">
                            <Skeleton width={60} height={20} />
                            <Skeleton width={50} height={30} className="rounded-md" />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default HapinnessCardSkeletons;
