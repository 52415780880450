import React from 'react'
import Skeleton from 'react-loading-skeleton'

const CarouselSkeleton = ({ skeletonCount }: { skeletonCount: number }) => {
    return (
        <div className="carousel-container">
            <div className="cards">
                {Array.from({ length: skeletonCount }).map((_, index) => (
                    <div key={index} className="card skeleton-card">
                        <Skeleton height={387} className="rounded-lg w-full h-full" />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CarouselSkeleton