"use client"
import { PageMetadataItem } from '@/types/next';
import dynamic from 'next/dynamic';
import Carousel from './Carousel';
const ReserveTableBar = dynamic(() => import('./ReserveTableBar'), { ssr: false });


interface BannerSecProps {
    currentPageMetadata?: PageMetadataItem;
}
const BannerSec: React.FC<BannerSecProps> = ({ currentPageMetadata }): JSX.Element => {
    return (
        <section className='banner-sec bg-primary-1 pt-8 pb-6 md:pt-[36px] md:pb-[36px] relative' id='Reservation'>
            <div className='container'>
                {/* Header section with title and description */}
                <div className='mb-4 md:mb-[26px]  max-w-[800px] w-full mx-auto'>
                    {
                        currentPageMetadata?.page_title &&
                        <h1 className="font-poppins text-xl md:text-[32px] font-normal leading-normal md:leading-[38.4px] text-wireframe-heading text-center animate-slide-up-fade">
                            {currentPageMetadata?.page_title}
                        </h1>
                    }
                    {
                        currentPageMetadata?.page_description &&
                        <p className='font-poppins text-[17px] md:text-6 text-primary-5 text-center italic font-semibold md:font-bold animate-fade-up'> {currentPageMetadata?.page_description}</p>
                    }
                </div>

                {/* Slider for Banner Images */}
                <Carousel />

                {/* Table reservation Bar */}
                <ReserveTableBar />
            </div>
        </section>
    );
};

export default BannerSec;
