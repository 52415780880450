"use client"
import Icon from "@/components/Icon";
import Button from "@/components/ui/Button";
import Image from "next/image";
import { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "@/store/store";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import useViewportWidth from "@/hooks/useViewportWidth";
import Link from "next/link";
import { getPromotionButtonText, handlePromotionButtonClick } from "@/lib/utils";

const WhatsOnBBQ = () => {
    const prevRef = useRef<HTMLButtonElement>(null);
    const nextRef = useRef<HTMLButtonElement>(null);
    const { results, loading } = useSelector((state: AppState) => state.promotionsVouchers);
    const [visibleCount, setVisibleCount] = useState<number>(6);
    const width = useViewportWidth();
    const selectedBranch = useSelector((state: AppState) => state.branches.saved);
    const dispatch = useDispatch<AppDispatch>();

    const handleClick = () => {
        setVisibleCount((prevCount) => Math.min(prevCount + 6, results?.promotion.length));
    };

    return (
        <section className="md:py-[25px] pb-6 relative" id="WhatsOnBBQ">
            <div className="container">
                <div className="flex items-center justify-between md:mb-9 mb-4">
                    <h2 className="font-poppins text-xl md:text-[32px] font-medium md:leading-[38.4px] text-wireframe-heading">
                        Sizzling Deals (At your selected restaurant)
                    </h2>
                    <div className="flex gap-3 items-center">
                        <Button variant="outline" href="/promotion" className="py-1.5 text-xs md:text-base whitespace-nowrap">
                            View All
                        </Button>
                        <div className="md:flex hidden items-center gap-[12px]">
                            <button className="w-[52px] h-[52px] rotate-[-180deg]" ref={prevRef}>
                                <Icon name="sliderRight" className="w-full h-full" />
                            </button>
                            <button className="w-[52px] h-[52px]" ref={nextRef}>
                                <Icon name="sliderRight" className="w-full h-full" />
                            </button>
                        </div>
                    </div>
                </div>

                {/* Swiper Carousel for desktop view */}
                <Swiper
                    modules={[Navigation]}
                    slidesPerView={3}
                    spaceBetween={30}
                    navigation={{
                        prevEl: prevRef.current,
                        nextEl: nextRef.current,
                    }}
                    breakpoints={{
                        300: {
                            slidesPerView: 2.2,
                            spaceBetween: 10,
                        },
                        1024: {
                            slidesPerView: 3,
                        },
                    }}
                    onSwiper={(swiper) => {
                        if (swiper && swiper.params && swiper.params.navigation) {
                            const navigation = swiper.params.navigation;
                            if (typeof navigation !== "boolean") {
                                navigation.prevEl = prevRef.current;
                                navigation.nextEl = nextRef.current;
                                swiper.navigation.destroy();
                                swiper.navigation.init();
                                swiper.navigation.update();
                            }
                        }
                    }}
                    className="tmbMySwiper md:!block !hidden"
                >
                    {loading ? (
                        // Display skeleton loader when loading
                        Array.from({ length: 3 }).map((_, index) => (
                            <SwiperSlide key={index}>
                                <div className="border border-[#E0E0E0] rounded-xl p-6 flex flex-col h-full">
                                    <Skeleton height={24} className="mb-4" />
                                    <Skeleton height={300} className="mb-4" />
                                    <Skeleton height={80} className="flex-grow mb-4" />
                                    <Skeleton height={40} />
                                </div>
                            </SwiperSlide>
                        ))
                    ) : (
                        results?.promotion &&
                        results?.promotion.length > 0 &&
                        results?.promotion?.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div className="border border-[#E0E0E0] rounded-xl p-6 flex flex-col justify-between h-[460px] animate-zoom">
                                    <div>
                                        <h3 className="text-[24px] leading-7 font-poppins font-medium text-[#21272A] mb-4 line-clamp-2">
                                            {item.promotion_title}
                                        </h3>
                                        <Link
                                            href={`/promotion/${item.promotion_alias.replace(
                                                "https://api.barbequenation.com/promotion/",
                                                ""
                                            )}`}
                                        >
                                            <Image
                                                src={item.promotion_image}
                                                width={250}
                                                height={200}
                                                alt={item.promotion_title || "Promotion Image"}
                                                className="rounded-xl mb-4 h-[200px] w-full object-fill"
                                            />
                                        </Link>
                                        <div className="font-inter-16-regular mb-4 line-clamp-3 ">
                                            {item.promotion_description}
                                        </div>
                                    </div>
                                    <div className="mt-auto">
                                        <Button
                                            id={`home-promotion-btn-${getPromotionButtonText(item.promotion_url).toLowerCase().split(" ")[0]}-${item?.promotion_id}`}
                                            onClick={() => handlePromotionButtonClick(item.promotion_url, selectedBranch, dispatch)}
                                            variant="primary"
                                            className="text-[#FBF6F5] w-full"
                                        >
                                            {getPromotionButtonText(item.promotion_url)}
                                        </Button>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                    )}
                </Swiper>

                {/* Grid layout for mobile view */}
                {width < 768 && (
                    <div className="md:hidden block">
                        <div className="grid grid-cols-2 gap-[16px]">
                            {loading
                                ? // Display skeleton loaders when loading
                                Array.from({ length: visibleCount }).map((_, index) => (
                                    <div key={index} className="border border-[#E0E0E0] rounded-lg p-2 flex flex-col h-full">
                                        <Skeleton height={144} className="mb-[12px]" />
                                        <Skeleton height={20} className="mb-4" />
                                        <Skeleton height={60} className="flex-grow mb-4" />
                                        <Skeleton height={40} />
                                    </div>
                                ))
                                : results?.promotion &&
                                results?.promotion.length > 0 &&
                                results?.promotion.slice(0, visibleCount)?.map((item, index) => (
                                    <div key={index} className="border border-[#E0E0E0] rounded-lg p-2 flex flex-col h-full justify-between">
                                        <Link
                                            href={`/promotion/${item.promotion_alias.replace(
                                                "https://api.barbequenation.com/promotion/",
                                                ""
                                            )}`}
                                        >
                                            <Image
                                                src={item.promotion_image}
                                                width={142}
                                                height={95}
                                                alt={item.promotion_title || "Promotion Image"}
                                                className="rounded-lg mb-[12px] h-[95px] w-full object-fill"
                                            />
                                        </Link>
                                        <h3 className="text-sm md:font-inter-16-regular !leading-[17px] !font-semibold !text-wireframe-heading mb-1 md:mb-4 line-clamp-2">
                                            {item.promotion_title}
                                        </h3>
                                        <div className="text-sm md:font-inter-16-regular mb-2 md:mb-4 line-clamp-2 flex-grow">
                                            {item.promotion_description}
                                        </div>
                                        <div className="mt-auto">
                                            <Button
                                                id={`home-promotion-btn-${getPromotionButtonText(item.promotion_url).toLowerCase().split(" ")[0]}-${item?.promotion_id}`}
                                                onClick={() => handlePromotionButtonClick(item.promotion_url, selectedBranch, dispatch)}
                                                variant="primary"
                                                className="text-[#FBF6F5] w-full text-sm py-1.5"
                                            >
                                                {getPromotionButtonText(item.promotion_url)}
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                        </div>
                        {visibleCount < results?.promotion.length && (
                            <div className="flex justify-center items-center w-full pt-4">
                                <button
                                    onClick={handleClick}
                                    className="px-[36px] py-[12px] justify-center font-inter text-base font-normal rounded-lg border duration-300 leading-[22.4px] bg-transparent text-primary-5 border-primary-5 hover:bg-primary-5 hover:text-white hover:shadow-shadow-btn flex items-center group max-[767px]:py-[7px]"
                                >
                                    <span>View more</span>
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </section>
    );
};

export default WhatsOnBBQ;
