"use client";
import { useState, useMemo } from "react";
import Icon, { IconName } from "@/components/Icon";
import { useRouter } from "next/navigation";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "@/store/store";
import { toast } from "react-toastify";
import { reservationSidbarOpenPopup } from "@/store/slices/auth/signPopupSlice";

type FixedBarItem = {
    iconName: IconName;
    iconText: string;
    iconLink: string;
};

const FixedBar = () => {
    const router = useRouter();
    const [activeSection, setActiveSection] = useState<string>("");
    const selectedBranch = useSelector((state: AppState) => state.branches.saved);
    const dispatch = useDispatch<AppDispatch>();
    const fixedBarData: FixedBarItem[] = useMemo(
        () => [
            {
                iconName: "reservation",
                iconText: "Reservation",
                iconLink: "Reservation",
            },
            {
                iconName: "catering",
                iconText: "Catering",
                iconLink: "/catering",
            },
            {
                iconName: "happinessCard",
                iconText: "Happiness Card",
                iconLink: "/vouchers",
            },
            {
                iconName: "delivery",
                iconText: "Delivery",
                iconLink: "/ubq-delivery",
            },
        ],
        []
    );

    // Handler for each icon click
    const handleClick = (item: FixedBarItem) => {
        if (item.iconLink === "Reservation") {
            if (!selectedBranch) {
                toast.warning('Please select a location!')
                return;
            }
            dispatch(reservationSidbarOpenPopup(true))
        } else {
            // Navigate for other items
            router.push(item.iconLink);
        }
    };

    return (
        <div className="bg-white px-5 py-4 fixed bottom-0 left-0 md:hidden block w-full z-[2] shadow-[0px_-1px_6px_0px_#00000040]">
            <div className="flex items-center gap-6 justify-between">
                {fixedBarData?.map((item, index) => (
                    <div
                        key={index}
                        className="text-center cursor-pointer"
                        onClick={() => handleClick(item)}
                    >
                        <Icon
                            name={item.iconName}
                            className={`w-6 mx-auto mb-2 ${activeSection === item.iconLink ? "OrangeFilled" : ""
                                }`}
                        />
                        <p
                            className={`text-xs leading-[14.4px] font-inter font-normal ${activeSection === item.iconLink ? "text-primary-5" : "text-gray-600"
                                }`}
                        >
                            {item.iconText}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FixedBar;
