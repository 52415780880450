import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Import Swiper's core styles
import 'swiper/css/pagination'; // Import pagination module styles
import { Pagination, Autoplay } from 'swiper/modules';
import Image from 'next/image';
import Icon from '@/components/Icon';
import Button from '@/components/ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '@/store/store';
import { fetchPromotionsVouchers } from '@/store/slices/promotionsAndVoucher';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; 
import { getPromotionButtonText, handlePromotionButtonClick } from '@/lib/utils';

const SliderSection = () => {
  // Fetching promotions, loading, and error state from the Redux store
  const { error, loading, results } = useSelector((state: AppState) => state.promotionsVouchers);
  const selectedBranch = useSelector((state: AppState) => state.branches.saved);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(
      fetchPromotionsVouchers({
        lat: '',
        long: '',
        branch_id: selectedBranch?.details.branch_id ? +selectedBranch?.details.branch_id : 0,
      })
    );
  }, [dispatch, selectedBranch]);

  return (
    <div className="md:my-8 mb-8 sidebar-slider relative slider-sec">
      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        loop={true}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        navigation
        modules={[Pagination, Autoplay]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        {loading ? (
          // Skeleton loader displayed while data is loading
          Array.from({ length: 3 }).map((_, index) => (
            <SwiperSlide key={index}>
              <div className="relative">
                <Skeleton height={240} className="rounded-lg w-full" />
                <div className="absolute bottom-4 left-4 right-4 p-4 bg-[#FFF6F0] rounded-xl shadow-lg">
                  <div className="flex gap-3 items-center mb-4">
                    <Skeleton width={150} height={20} />
                    <Skeleton circle width={30} height={30} />
                  </div>
                  <Skeleton count={2} />
                  <Skeleton width={100} height={30} className="mt-4" />
                </div>
              </div>
            </SwiperSlide>
          ))
        ) : results && results?.promotion.length > 0 ? (
          results?.promotion?.map((item, index) => (
            <SwiperSlide key={item.promotion_id}>
              <div className="relative">
                {/* Promotion image */}
                <Image
                  src={item.promotion_image}
                  alt={item.promotion_title}
                  width={375}
                  height={240}
                  priority={index === 0} // Ensure the first image is prioritized for faster LCP
                  loading={index === 0 ? "eager" : "lazy"} // Load first image eagerly, others lazily
                  className="rounded-lg w-full h-[240px] object-cover"
                />
                <div className="absolute bottom-4 left-4 right-4 p-4 bg-[#FFF6F0] rounded-xl shadow-lg">
                  <div className="flex gap-3 items-center mb-4">
                    <h3 className="font-inter text-lg font-semibold text-[#323131]">{item.promotion_title}</h3>
                    <Icon name="bbq" />
                  </div>
                  <div>
                    <p
                      className="font-inter text-sm font-medium text-[#737373] line-clamp-2"
                      dangerouslySetInnerHTML={{ __html: item.promotion_description }}
                    ></p>
                    {item?.promotion_url && (
                      <Button
                        id={`home-promotion-btn-${getPromotionButtonText(item?.promotion_url).toLowerCase().split(" ")[0]}-${item?.promotion_id}`}
                        onClick={() => handlePromotionButtonClick(item?.promotion_url, selectedBranch, dispatch)}
                        variant="primary"
                        className="whitespace-nowrap mt-4 w-full"
                      >
                        {getPromotionButtonText(item.promotion_url)}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))
        ) : (
          <div className="flex justify-center items-center h-64 text-gray-500">
            {error ? 'Error loading promotions' : 'No Promotions Available'}
          </div>
        )}
      </Swiper>
    </div>
  );
};

export default SliderSection;
