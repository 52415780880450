"use client";
import Image from "next/image";
import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import { useSelector } from "react-redux";
import { AppState } from "@/store/store";
import CarouselSkeleton from "@/components/skeletons/CarouselSkeleton";
import Link from "next/link";

const Carousel: React.FC = () => {
  const [current, setCurrent] = useState(0);
  const [startX, setStartX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { results, loading } = useSelector((state: AppState) => state.promotionsVouchers);
  const getSavedRecords = useSelector((state: AppState) => state.branches.saved);
  const carouselItems = useMemo(() => {
    if (results?.promotion) {
      const items = [...results.promotion];

      // If the length is 1 return items without duplication
      if (items.length <= 1) {
        return items;
      }

      // If length is 3 or more but less than 5, duplicate until reaching 5 items
      if (items.length < 5) {
        return [...items, ...results.promotion].slice(0, 5);
      }

      // If length is already 5 or more, return items as they are
      return items;
    }
    return [];
  }, [results]);


  useEffect(() => {
    const updateIsMobile = () => setIsMobile(window.innerWidth <= 768);
    updateIsMobile();
    window.addEventListener("resize", updateIsMobile);
    return () => window.removeEventListener("resize", updateIsMobile);
  }, []);

  const handleNext = useCallback(() => {
    setCurrent((prev) => (prev + 1) % carouselItems.length);
  }, [carouselItems.length]);

  const handlePrev = useCallback(() => {
    setCurrent((prev) => (prev - 1 + carouselItems.length) % carouselItems.length);
  }, [carouselItems.length]);

  const handleSwipeStart = useCallback((e: React.MouseEvent | React.TouchEvent) => {
    setStartX("touches" in e ? e.touches[0].clientX : e.clientX);
    setIsDragging(true);
  }, []);

  const handleSwipeMove = useCallback((e: React.MouseEvent | React.TouchEvent) => {
    if (!isDragging) return;
    const currentX = "touches" in e ? e.touches[0].clientX : e.clientX;
    if (Math.abs(startX - currentX) > 10) {
      startX - currentX > 0 ? handleNext() : handlePrev();
      setIsDragging(false);
    }
  }, [isDragging, startX, handleNext, handlePrev]);

  useEffect(() => {
    const interval = setInterval(handleNext, 3000);
    return () => clearInterval(interval);
  }, [handleNext]);

  const getPositionClass = (index: number) => {
    const positions = ["prevSlide2", "prevSlide", "activeSlide", "nextSlide", "nextSlide2"];
    return positions[(index - current + carouselItems.length) % carouselItems.length];
  };

  const renderCarouselContent = useMemo(() => {
    const renderSkeleton = () => {
      const skeletonCount = isMobile ? 2 : 5;
      return <CarouselSkeleton skeletonCount={skeletonCount} />;
    };

    if (!getSavedRecords) {
      return (
        <div
          className="relative"
        >
          <Image src="/images/defaultHero.jpg" alt="Home banner" width={430} height={200} priority={true} className="w-full rounded-md h-[200px] md:h-[350px] lg:h-[430px]" />
          <div className="max-w-[770px] w-full mx-auto px-4 text-center md:text-left absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <h2 className="text-white font-bold text-[40px] sm:text-[50px] md:text-[60px] lg:text-[70px] xl:text-[80px] font-inter">
              Let us serve you better
            </h2>
          </div>
        </div>
      );
    }

    if (loading) {
      return renderSkeleton();
    }

    if (carouselItems?.length === 1 && !isMobile) {
      // Render a single image if there's only one promotion on desktop
      const item = carouselItems[0];
      return (
        <div className="relative w-full h-[430px] flex justify-center items-center">
          <Image
            src={item.promotion_image}
            alt={item.promotion_title || "Promotion"}
            width={720}
            height={430}
            className="rounded-lg object-fill max-w-[720px] w-full h-[430px]"
            priority
          />
        </div>
      );
    }


    return isMobile ? (
      <div className="relative menu-slider">
        {carouselItems?.length > 0 && (
          <link rel="preload" href={carouselItems[0].promotion_image} as="image" />
        )}
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          pagination={{ clickable: true }}
          modules={[Pagination, Autoplay]}
          loop={carouselItems.length > 1}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          breakpoints={{
            300: { slidesPerView: 1, spaceBetween: 10 },
            768: { slidesPerView: Math.min(2, carouselItems.length) },
          }}
          className="!pb-10"
        >
          {carouselItems?.map((item, index) => (
            <SwiperSlide key={`${index}-${item.promotion_id}`}>
              <Link href={`/promotion/${item.promotion_alias.replace("https://api.barbequenation.com/promotion/", "")}`}>
                <Image
                  src={item.promotion_image}
                  width={385}
                  height={233}
                  alt={item.promotion_title || `Slide ${index + 1}`}
                  priority={index === 0}
                  loading={index === 0 ? "eager" : "lazy"}
                  className="rounded-lg w-full select-none h-[220px]"
                  quality={65}
                />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    ) : (
      <div
        className="carousel-container"
        ref={containerRef}
        onMouseDown={handleSwipeStart}
        onMouseMove={handleSwipeMove}
        onMouseUp={() => setIsDragging(false)}
        onMouseLeave={() => setIsDragging(false)}
        onTouchStart={handleSwipeStart}
        onTouchMove={handleSwipeMove}
        onTouchEnd={() => setIsDragging(false)}
      >
        <div className="cards">
          {carouselItems?.map((item, index) => (
            <div key={`${index}-${item.promotion_id}`} className={`card ${getPositionClass(index)}`}>
              <Link href={`/promotion/${item.promotion_alias.replace("https://api.barbequenation.com/promotion/", "")}`} className="image">
                <Image
                  src={item.promotion_image}
                  alt={item.promotion_title || `Slide ${index + 1}`}
                  fill
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                  className="rounded-lg select-none"
                  priority={index === 0}
                  loading={index === 0 ? "eager" : "lazy"}
                  quality={65}
                />
              </Link>
            </div>
          ))}
        </div>
      </div>
    );
  }, [getSavedRecords, loading, isMobile, carouselItems, handleSwipeStart, handleSwipeMove, getPositionClass]);

  return renderCarouselContent;
};

export default Carousel;
