"use client"
// import { scrollToElement } from "@/lib/helper";
import Image from "next/image";
import Link from "next/link";

const SideFixedButtons = () => {
  return (
    <div className="fixed top-1/2 right-[30px] translate-y-[-50%] z-30 hidden md:block">
      {/* <button className="md:w-[58px] h-8 w-8 md:h-[58px] bg-primary-2 rounded-full flex md:mb-6 mb-3 animate-custom-pulse" onClick={() => scrollToElement("Reservation")}>
        <Image
          src="/images/food.svg"
          className="mx-auto md:w-7 w-5 block h-full"
          width={28}
          height={28}
          alt="image-description"
        />
      </button> */}
      <Link
        href="https://api.whatsapp.com/send/?phone=919071788854"
        target="_blank"
        className="md:w-[58px] h-8 w-8 md:h-[58px] bg-[#C3FFCD] rounded-full flex animate-custom-pulse"
      >
        <Image
          src="/images/ri_whatsapp-fill.svg"
          className="mx-auto md:w-7 w-5 block h-full"
          width={28}
          loading="lazy"
          height={28}
          alt="image-description"
        />
      </Link>
    </div>
  );
};

export default SideFixedButtons;
